import { Outlet } from "react-router-dom";
import Directory from "../../components/directory/directory.component";

const Home = () => {
  return (
    <div >      
      <h1>Any content here</h1>
      <Directory />
      <h1>Any content here</h1>
      <Outlet />
    </div>
  );
};

export default Home;
