import styled from "styled-components";

export const SignInContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 25rem;
    @media screen and (max-width: 792px) {
       width: fit-content;
      }
`
export const H2Fonts = styled.h2`
    margin: 10px 0;
`
export const ButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
`
