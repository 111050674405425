import { Outlet } from "react-router-dom";

const 
UnderConstruction = () => {
  return (
    <div >

      <div
      style={{display: "flex", justifyContent: "center", alignItems: "center", height: "90vh", fontSize: "3rem"}} 
      >This Page is under construction</div>
      <Outlet />
    </div>
  );
};

export default UnderConstruction;
