import { useContext } from 'react';
import { CartContext } from '../../contexts/cart.context';
import { ShpoppingIcon, CartIconContainer, ItemCount } from './cart-icon.styles';

const CartIcon = () => {
    const { isCartOpen, setIsCartOpen, cartCount } = useContext(CartContext);

    const toggleIsCartOpen = () => setIsCartOpen(!isCartOpen);
    return (
        <CartIconContainer onClick={toggleIsCartOpen}>
            <ShpoppingIcon></ShpoppingIcon>
            <ItemCount>{cartCount}</ItemCount>
        </CartIconContainer>

    );
}
export default CartIcon;